
import { defineComponent } from 'vue'
import FPMoney, { Values } from '../fp-money/fp-money'

export default defineComponent({
  name: 'Examples',
  data() {
    return {
      disabled: null as any,
      displayOnly: null as any,

      curSelectionValues: {} as Values
    }
  },
  mounted() {
    new FPMoney({
      container: document.querySelector('#exampleBasic') as HTMLDivElement
    })

    new FPMoney({
      container: document.querySelector('#exampleSelection') as HTMLDivElement,
      showSelection: true,
      onChange: (values) => {
        this.curSelectionValues = values
      }
    })

    this.disabled = new FPMoney({
      container: document.querySelector('#exampleDisabled') as HTMLDivElement,
      value: 86753.09,
      disabled: true
    })

    this.displayOnly = new FPMoney({
      container: document.querySelector('#exampleDisplayOnly') as HTMLDivElement,
      value: 86753.09,
      displayOnly: true
    })

    new FPMoney({
      container: document.querySelector('#exampleMinValue') as HTMLDivElement,
      minValue: 0
    })
  },
  methods: {
    toggleDisabled() {
      this.disabled.setDisabled(!this.disabled.disabled)
    },
    toggleDisplayOnly() {
      this.displayOnly.setDisplayOnly(!this.displayOnly.displayOnly)
    }
  }
})
