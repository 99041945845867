
import { defineComponent } from 'vue'
import FPMoney from '../fp-money/fp-money'

export default defineComponent({
  data() {
    return {
      value: '',
      format: '',
      display: '',
      currency: ''
    }
  },
  mounted() {
    const fp = new FPMoney({
      container: document.querySelector('#example') as HTMLDivElement,
      value: 86753.09,
      // minValue: 200.00,
      // maxValue: 500.00,
      // step: 0.01,
      onChange: (values) => {
        this.value = values.value
        this.format = values.format
        this.display = values.display
        this.currency = values.currency
      }
    })
    // setInterval(() => {
    //   fp.setDisplayOnly(!fp.displayOnly)
    // }, 5000)
  }
})
