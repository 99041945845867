
import { defineComponent } from 'vue'
import { Values, Currencies } from '@/fp-money/fp-money'
import fpmoney from '../fp-money/fp-money'
import Chance from 'chance'
const chance = new Chance()

export default defineComponent({
  components: { fpmoney },
  data() {
    return {
      value: 8675309 as any,
      int: '',
      format: '',
      display: '',
      currency: '',
      currencies: {
        KFL: {
          symbol: '%',
          fraction: 3
        },
        PER: {
          symbol: '%',
          fraction: 2
        },
        CAD: {
          symbol: '$',
          fraction: 2
        }
      } as Currencies,
      locale: 'en-US'
    }
  },
  mounted() {
    // setInterval(() => {
    //   // this.value = Math.random() * 1000
    //   this.value = chance.integer({ min: 1000, max: 100000 })
    // }, 5000)

    setTimeout(() => {
      this.currencies = {
        PER: {
          symbol: '%',
          fraction: 2
        },
        CAD: {
          symbol: '$',
          fraction: 2
        }
      }

      this.currency = 'CAD'

      // setTimeout(() => {
      //   this.value = 500
      // }, 1000)

      // setTimeout(() => {
      //   this.currency = 'cad'
      // }, 2000)

      // setTimeout(() => {
      //   this.currency = 'per'
      // }, 3000)
    }, 1000)
  },
  methods: {
    change(values: Values) {
      // console.log(values)
    }
  }
})
