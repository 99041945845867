
import { defineComponent } from 'vue'
import Heading from './heading.vue'

export default defineComponent({
  name: 'App',
  components: {
    Heading
  }
})
