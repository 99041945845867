
import { defineComponent } from 'vue'
import { fractionToInt, intToFraction, displayValue, getLocale } from '../fp-money/fp-money'

export default defineComponent({
  name: 'Functions',
  data() {
    return {
    }
  },
  methods: {
    fractionToInt(value: number, fraction: number) {
      return fractionToInt(value, fraction)
    },
    intToFraction(value: number, fraction: number) {
      return intToFraction(value, fraction)
    },
    displayValue(value: number, currency: string, fraction: number, locale: string) {
      return displayValue(value, currency, fraction, locale)
    },
    getLocale() {
      return getLocale()
    }
  }
})
